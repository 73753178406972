@if (loggedOut) {
    <mat-card>
        <mat-card-header>
            <mat-card-title>
            </mat-card-title>
        </mat-card-header>
        <mat-card-actions align="end">
            <button class="gsi-material-button" (click)="loginGoogle()">
                <div class="gsi-material-button-state">
                </div>
                <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                    </div>
                    <span class="gsi-material-button-contents">Sign in with Google</span>
                    <span style="display: none;">Sign in with Google</span>
                </div>
            </button>
        </mat-card-actions>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-card>
        <mat-card-header>
            <mat-card-title>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>
                @if (error) {
                    {{errorText}}
                }
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput type="text" [(ngModel)]="email">
                    @if (email) {
                        <mat-icon matSuffix (click)="email=''">close</mat-icon>
                    }
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
            </p>
        </mat-card-content>
        <mat-card-actions align="end">
            <button class="gsi-material-button" (click)="register()">
                <div class="gsi-material-button-state">
                </div>
                <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                        <i class="material-icons" >
                            how_to_reg
                        </i>
                    </div>
                    <span class="gsi-material-button-contents">Register</span>
                    <span style="display: none;">Register</span>
                </div>
            </button>
            <button class="gsi-material-button" (click)="loginEmail()">
                <div class="gsi-material-button-state">
                </div>
                <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                        <i class="material-icons" >
                            email
                        </i>
                    </div>
                    <span class="gsi-material-button-contents">Sign in with Email</span>
                    <span style="display: none;">Sign in with Email</span>
                </div>
            </button>
            <button class="gsi-material-button" (click)="resetLogin()">
                <div class="gsi-material-button-state">
                </div>
                <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                        <i class="material-icons" >
                            restart_alt
                        </i>
                    </div>
                    <span class="gsi-material-button-contents">Password Reset</span>
                    <span style="display: none;">Password Reset</span>
                </div>
            </button>
        </mat-card-actions>
    </mat-card>
} @else {
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Logged In
            </mat-card-title>
            <mat-card-subtitle>
                {{displayName}}
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions align="end">
            <button class="gsi-material-button" (click)="logout()">
                <div class="gsi-material-button-state">
                </div>
                <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                        <i class="material-icons" >
                            logout
                        </i>
                    </div>
                    <span class="gsi-material-button-contents">Logout</span>
                    <span style="display: none;">Logout</span>
                </div>
            </button>
        </mat-card-actions>
    </mat-card>
}
